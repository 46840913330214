import './App.css';
import * as React from "react";
import { useState,useEffect } from "react";
import { DataGridPremium, GridColDef, svSE, GridToolbarContainer, GridToolbarExport,GridToolbarQuickFilter,GridToolbarFilterButton } from "@mui/x-data-grid-premium";
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

function Cardholders() {
	  
	 const [tableData,setTableData]=useState([]);
		 
			 function loadTable() {
			 fetch("https://scoutwise.haag.se/api/cardholders")
				 .then((response) => response.json())
				 .then((data) => setTableData(data))
			 }
		 
		   useEffect(()=>{
	 
			 loadTable();
				 
			 }, []);
	  
	  const savetoDB = (newRow,oldRow) =>{
		  
		  let bodydata = {
			"table":"cardholders",
			"row":newRow
		  }
		
		  fetch("https://scoutwise.haag.se/api/update", { 
			method: "post",
			body: JSON.stringify(bodydata)
		  })
		  return {...newRow};
		};
		
		const defaultValues = {};
		
		const [formValues, setFormValues] = useState(defaultValues);
		
		
		const handleChange = (e) => {
		  const { name, value } = e.target;
		  setFormValues({
			...formValues,
			[name]: value,
		  });
		};
		
		const [open, setOpen] = React.useState(false);
		
		const handleClickOpen = () => {
				setOpen(true);
			  };
			
		 const handleClose = () => {
				setOpen(false);
			  };
			  
		const handleSubmit = (event) => {
				  event.preventDefault();
				  let bodydata = {
					  "table":"cardholders",
					  "row":formValues
					}
					fetch("https://scoutwise.haag.se/api/add", { 
					  method: "post",
					  body: JSON.stringify(bodydata)
					})
					.then((data) => loadTable())
					.then((data) => setOpen(false))
					.then((data) => setFormValues({}));
				 ;
				 
				};
			
			  
	  
	  const columns: GridColDef[] = [
		{ field: 'id', headerName: 'id', type:'integer', },
		{ field: 'name', headerName: 'Namn', type:'string', editable: true},
		{ field: 'cardname', headerName: 'Kortnamn', type:'string', editable: true},
		{ field: 'email', headerName: 'Epost', type:'string', editable: true},
		{ field: 'project_name', headerName: 'Projektnamn', type:'string', editable: true},
		{ field: 'project_id', headerName: 'Projekt-ID', type:'text', editable: true},
		{ field: 'cost_center_id', headerName: 'Resultatenhet (RE)', type:'text', editable: true},
		{ field: 'cost_object_id', headerName: 'Kostnadsbärare (KBR)', type:'text', editable: true},
	  ];
	  
	  
	  function CustomToolbar() {
		  return (
			<GridToolbarContainer >
			
				<GridToolbarFilterButton />
			  <GridToolbarExport />
			  <Button color="primary" startIcon={<AddIcon />} onClick={handleClickOpen}>
				  Lägg till
				</Button>
			  <GridToolbarQuickFilter sx={{ marginLeft: "auto" }} />
			</GridToolbarContainer>
		  );
		}
	  
	  return (
		<div style={{
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			height: '85vh',
			margin:40 }}>
			
			
			<Dialog open={open} onClose={handleClose} >
			<form onSubmit={handleSubmit}>
				<DialogTitle>Lägg till Korthållare</DialogTitle>
				<DialogContent sx={{minWidth: 500 }}>
				  <TextField
					autoFocus
					margin="dense"
					id="name"
					label="Namn"
					type="text"
					fullWidth
					variant="standard"
					name="name"
					value={formValues.name}
					onChange={handleChange}
				  />
				  <br /><br />
				  <TextField
					  margin="dense"
					  id="cardname"
					  label="Namn på kort"
					  type="text"
					  fullWidth
					  variant="standard"
					  name="cardname"
					  value={formValues.cardname}
					  onChange={handleChange}
					/>
				<br /><br />
				  <TextField
					  margin="dense"
					  id="email"
					  label="Epost"
					  type="text"
					  fullWidth
					  variant="standard"
					  name="email"
					  value={formValues.email}
					  onChange={handleChange}
					/>
					<br /><br />
					  <TextField
						  margin="dense"
						  id="project_id"
						  label="Projektnummer"
						  type="text"
						  fullWidth
						  variant="standard"
						  name="project_id"
						  value={formValues.project_id}
						  onChange={handleChange}
						/>
					<br /><br />
					  <TextField
						  margin="dense"
						  id="project_name"
						  label="Projektnamn"
						  type="text"
						  fullWidth
						  variant="standard"
						  name="project_name"
						  value={formValues.project_name}
						  onChange={handleChange}
						/>
					<br /><br />
					  <TextField
						  margin="dense"
						  id="cost_center_id"
						  label="Resultatenhet (RE)"
						  type="text"
						  fullWidth
						  variant="standard"
						  name="cost_center_id"
						  value={formValues.cost_center_id}
						  onChange={handleChange}
						/>
					<br /><br />
					  <TextField
						  margin="dense"
						  id="cost_object_id"
						  label="Kostnadsbärare (KBR)"
						  type="text"
						  fullWidth
						  variant="standard"
						  name="cost_object_id"
						  value={formValues.cost_object_id}
						  onChange={handleChange}
						/>
						
				
				  
				</DialogContent>
				<DialogActions>
				  <Button onClick={handleClose}>Avbryt</Button>
				  <Button type="submit">Lägg till</Button>
				</DialogActions>
				</form>
			  </Dialog>
			
			
			
		<DataGridPremium 
		  initialState={{
			columns: {
			  columnVisibilityModel: {
				id: false,
			  },
			},
		  }} 
		  localeText={svSE.components.MuiDataGrid.defaultProps.localeText}
		  sx={{ fontSize: 9 }} 
		  rows={tableData} 
		  columns={columns}  
		  density="compact" 
		  components={{ Toolbar: CustomToolbar}} 
		  disableColumnSelector
		  disableDensitySelector
		  componentsProps={{
			toolbar: {
			  showQuickFilter: true,
			  quickFilterProps: { debounceMs: 500 },
			},
		  }} 
		  processRowUpdate={savetoDB}
		  experimentalFeatures={{ 
			newEditingApi: true }} 
		   />
		  
	   </div>
	  );
}
	
	
	
export default Cardholders;