import './App.css';
import * as React from "react";
import { useState,useEffect } from "react";
import { DataGridPremium, GridColDef, GridToolbar, svSE, useGridApiRef } from "@mui/x-data-grid-premium";
import CloseIcon from '@mui/icons-material/Close';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import CheckIcon from '@mui/icons-material/Check';


function WisetableTransfers() {

  var heads = {}
  try {
    const cred = JSON.parse(localStorage.getItem("credentials"));
    const apikey = cred['access_token'];
    heads = {'Authorization':'Bearer '+apikey,  'Content-Type': 'application/json'}
  }
  catch {
    heads = {}
  }
  
  const apiRef = useGridApiRef();
  const [tableData,setTableData]=useState([]);

  const fetchTable = async () => {
    
    fetch("https://scoutwise.haag.se/api/transfers", { headers:heads})
    .then((response) => response.json())
    .then((data) => setTableData(data))
  }

  useEffect(()=>{
 
    fetchTable();
 // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  
  function getCategory(params) {
    if (params.row.accounting_category_override === "") { return params.row.accounting_category; }
    else { return params.row.accounting_category_override; }
  }
  
  function getProjectId(params) {
    if (params.row.project_id_override === null) { return params.row.project_id; }
    else { return params.row.project_id_override; }
  }
  
  function getCostObject(params) {
    if (params.row.cost_object_id_override === null) { return params.row.cost_object_id; }
    else { return params.row.cost_object_id_override; }
  }
  
  function getCostCenter(params) {
    if (params.row.cost_center_id_override === null) { return params.row.cost_center_id; }
    else { return params.row.cost_center_id_override; }
  }
  
  function getIcon(params){
    if (params.aggregation) { return params.formattedValue;}
    else {
      if (params.value === 1) {
        return <CloseIcon color="disabled" />;
      }
      return <ReceiptLongIcon color="disabled" />;
    }
  }
  
  function getCheckIcon(params){
    if (params.aggregation) { return params.formattedValue;}
    else {
      if (params.value === 1) {
        return <CheckIcon color="disabled" />;
      }
      return "";
    }
  }
  
  
  function getDate(params) {
    var date = new Date(params.row.date);
    if(!isNaN(date.getTime())) {
      return date;
    }
    else { return ""; }
  }
  
  
  const savetoDB = (newRow,oldRow) =>{
      
      let bodydata = {
        "table":"cardtransactions",
        "row":newRow
      }
    
      fetch("https://scoutwise.haag.se/api/update", { 
        method: "post",
        body: JSON.stringify(bodydata)
      })
      return {...newRow};
    };
  
  const columns: GridColDef[] = [
    { field: 'date', headerName: 'Datum', type:'dateTime', valueGetter: getDate},
    { field: 'id', headerName: 'id', type:'number', },
    { field: 'type', headerName: 'Typ', type:'string', },
    { field: 'transaction_type', headerName: 'Transaktionstyp', type:'string'},
    { field: 'amount_sek', headerName: 'Summa (SEK)', type:'number' },
    { field: 'note', headerName: 'Notering', type:'string', width:250 },
    { field: 'admin_note', headerName: 'Admin Notering', type:'string', width:250, editable: true },
    { field: 'project_id', headerName: 'Projekt', type:'text',editable:false},
    { field: 'cost_center_id', headerName: 'Resultatenhet', type:'text',editable:false},
    { field: 'cost_object_id', headerName: 'Kostnadsbärare', type:'text',editable:false},
    
    { field: 'project_id_override', headerName: 'Projekt-ID', type:'text',editable:true, valueGetter:getProjectId },
    { field: 'cost_center_id_override', headerName: 'Resultatenhet (RE)', type:'text',editable:true, valueGetter:getCostCenter },
    { field: 'cost_object_id_override', headerName: 'Kostnadsbärare (KBR)', type:'text',editable:true, valueGetter:getCostObject },
  
    { field: 'accounting_category', headerName: 'Kategori (auto bokföring)', type:'singleSelect', valueOptions: [
      '1612 Kontantuttag',
      '1699 Vidarefaktureras',
      '1941 Wise SEK',
      '1942 Wise CZK',
      '1943 Wise EUR',
      '1944 Wise annan valuta',
      '4210 Material',
      '4230 Resor',
      '4240 Kost',
      '4260 Aktiviteter',
      '5800 Resor, personal och arbetsgrupper',
      '5840 Mat vid resor,  personal och arbetsgrupper',
      '5860 Logi, personal och arbetsgrupper',
      '5990 Övrigt reklam och PR',
      '6210 Telefon',
      '6590 Övriga kostnader',
      '7631 Personalrepresentation',
      '7640 Personalkaffe'
    ],editable:false},
    { field: 'accounting_category_override', headerName: 'Kategori (bokföring)', type:'singleSelect', valueOptions: [
      '1612 Kontantuttag',
      '1699 Vidarefaktureras',
      '1941 Wise SEK',
      '1942 Wise CZK',
      '1943 Wise EUR',
      '1944 Wise annan valuta',
      '4210 Material',
      '4230 Resor',
      '4240 Kost',
      '4260 Aktiviteter',
      '5800 Resor, personal och arbetsgrupper',
      '5840 Mat vid resor,  personal och arbetsgrupper',
      '5860 Logi, personal och arbetsgrupper',
      '5990 Övrigt reklam och PR',
      '6210 Telefon',
      '6590 Övriga kostnader',
      '7631 Personalrepresentation',
      '7640 Personalkaffe'
    ],editable:true,
      valueGetter: getCategory,
    groupingValueGetter: getCategory},
    { field: 'missingreceipt', headerName: 'Saknas underlag?', type:'number', renderCell: getIcon
  },
  { field: 'exclude_from_sie_export', headerName: 'Exkludera från SIE', type:'singleSelect', valueOptions: [
    { value: 1, label: 'Ja' },
    { value: 0, label: 'Nej' },
  ],editable:true, renderCell: getCheckIcon, align:'center'},
  ];
  
  return (
    <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '85vh',
        margin:40 }}>
        
    <DataGridPremium 
      apiRef={apiRef} 
      initialState={{
        columns: {
          columnVisibilityModel: {
            id: false,
            accounting_category: false,
            project_id: false,
            cost_center_id: false,
            cost_object_id: false
          },
        },
        aggregation: {
          model: {
            amount_sek: 'sum',
            missingreceipt: 'sum',
          },
        }
      }} 
      localeText={svSE.components.MuiDataGrid.defaultProps.localeText}
      sx={{ fontSize: 9 }} 
      rows={tableData} 
      columns={columns}  
      density="compact" 
      components={{ Toolbar: GridToolbar}} 
      disableColumnSelector
      disableDensitySelector
      componentsProps={{
        toolbar: {
          showQuickFilter: true,
          quickFilterProps: { debounceMs: 500 },
        },
      }} 
      processRowUpdate={savetoDB}
      experimentalFeatures={{ 
        aggregation: true,
        newEditingApi: true }} 
       />
   </div>
  );
}



export default WisetableTransfers;
