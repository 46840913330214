import './App.css';
import * as React from "react";
import { useState,useEffect } from "react";
import { DataGridPremium, GridColDef, svSE, useGridApiRef, GridToolbarContainer, GridToolbarExport,GridToolbarQuickFilter,GridToolbarFilterButton } from "@mui/x-data-grid-premium";
import LinearProgress from '@mui/material/LinearProgress';
import ReactCountryFlag from "react-country-flag"
import CloseIcon from '@mui/icons-material/Close';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import CheckIcon from '@mui/icons-material/Check';
import Button from '@mui/material/Button';

function Wisetable() {

  
  const apiRef = useGridApiRef();
  const [tableData,setTableData]=useState([]);
  const [loading, setLoading] = useState(true);
  
  var heads = {}
  try {
    const cred = JSON.parse(localStorage.getItem("credentials"));
    const apikey = cred['access_token'];
    heads = {'Authorization':'Bearer '+apikey,  'Content-Type': 'application/json'}
  }
  catch {
    heads = {}
  }
  
  function quickloadTable() {
    fetch("https://scoutwise.haag.se/api/transactions/100", { headers:heads})
        .then((response) => response.json())
        .then((data) => {setTableData(data); setLoading(false);  })
  }
  
  function loadTable() {
    fetch("https://scoutwise.haag.se/api/transactions/1000", { headers:heads})
        .then((response) => response.json())
        .then((data) => {setTableData(data); setLoading(false);   })
  }
  
  function loadFullTable() {
    fetch("https://scoutwise.haag.se/api/transactions", { headers:heads})
        .then((response) => response.json())
        .then((data) => {setTableData(data); setLoading(false);   })
  }

  useEffect(()=>{
    quickloadTable();
    loadTable();
    loadFullTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);
  
  function getCategory(params) {
    if (params.row.accounting_category_override === "") { return params.row.accounting_category; }
    else { return params.row.accounting_category_override; }
  }
  
  function getProjectId(params) {
    if (params.row.project_id_override === null) { return params.row.project_id; }
    else { return params.row.project_id_override; }
  }
  
  function getCostObject(params) {
    if (params.row.cost_object_id_override === null) { return params.row.cost_object_id; }
    else { return params.row.cost_object_id_override; }
  }
  
  function getCostCenter(params) {
    if (params.row.cost_center_id_override === null) { return params.row.cost_center_id; }
    else { return params.row.cost_center_id_override; }
  }
  
  function getIcon(params){
    if (params.aggregation) { return params.formattedValue;}
    else {
      if (params.value === 1) {
        return <CloseIcon color="disabled" />;
      }
      return <ReceiptLongIcon color="disabled" />;
    }
  }
  
  function getCheckIcon(params){
    if (params.aggregation) { return params.formattedValue;}
    else {
      if (params.value === 1) {
        return <CheckIcon color="disabled" />;
      }
      return "";
    }
  }
  
  function getFlag(params) {
     return <ReactCountryFlag countryCode={params.value} title={params.value} 
      style={{
          width: '1.5em',
          height: '1.2em',
      }}
      svg />;

  }
  
  function getDate(params) {
    var date = new Date(params.row.date);
    if(!isNaN(date.getTime())) {
      return date;
    }
    else { return ""; }
  }
  
  const savetoDB = (newRow,oldRow) =>{
      
      let bodydata = {
        "table":"cardtransactions",
        "row":newRow
      }
    
      fetch("https://scoutwise.haag.se/api/update", { 
        method: "post",
        body: JSON.stringify(bodydata)
      })
      return {...newRow};
    };
    
    
  const getTableState = () => {
    alert(JSON.stringify(apiRef.current.exportState()));
  };
  
  const restoreTableToday = () => {

  const today = new Date()
  /* today.setHours(today.getHours() + 7) */
  let startdate=today.toJSON().slice(0, 10)+"T00:00";

  let data ='{"aggregation":{"model":{"amount_sek":"sum","missingreceipt":"sum","lost_receipt":"sum","exclude_from_sie_export":"sum"}},"rowGrouping":{"model":["project_name"]},"pinnedColumns":{},"columns":{"columnVisibilityModel":{"id":false,"accounting_category":false},"orderedFields":["__row_group_by_columns_group__","id","date","amount_sek","missingreceipt","type","project_name","merchant","country","merchant_category","name","note","admin_note","accounting_category","accounting_category_override","lost_receipt","exclude_from_sie_export"]},"preferencePanel":{"open":false},"filter":{"filterModel":{"items":[{"columnField":"date","operatorValue":"after","id":56297,"value":"'+startdate+'"}],"linkOperator":"and","quickFilterValues":[],"quickFilterLogicOperator":"and"}},"sorting":{"sortModel":[{"field":"amount_sek","sort":"asc"}]},"pagination":{"pageSize":100,"page":0}}';
  apiRef.current.restoreState(JSON.parse(data));
  };
  
  const restoreTableYesterday = () => {
  
  const today = new Date()
  /* today.setHours(today.getHours() + 7) */
  const yesterday = new Date(today)
  yesterday.setDate(yesterday.getDate() - 1)
  let stopdate=today.toJSON().slice(0, 10)+"T00:00";
  let startdate=yesterday.toJSON().slice(0, 10)+"T00:00";
  
  let data ='{"aggregation":{"model":{"amount_sek":"sum","missingreceipt":"sum","lost_receipt":"sum","exclude_from_sie_export":"sum"}},"rowGrouping":{"model":["project_name"]},"pinnedColumns":{},"columns":{"columnVisibilityModel":{"id":false,"accounting_category":false},"orderedFields":["__row_group_by_columns_group__","id","date","amount_sek","missingreceipt","type","project_name","merchant","country","merchant_category","name","note","admin_note","accounting_category","accounting_category_override","lost_receipt","exclude_from_sie_export"]},"preferencePanel":{"open":false},"filter":{"filterModel":{"items":[{"columnField":"date","operatorValue":"after","id":56297,"value":"'+startdate+'"},{"columnField":"date","operatorValue":"before","id":96480,"value":"'+stopdate+'"}],"linkOperator":"and","quickFilterValues":[],"quickFilterLogicOperator":"and"}},"sorting":{"sortModel":[{"field":"amount_sek","sort":"asc"}]},"pagination":{"pageSize":100,"page":0}}';
  apiRef.current.restoreState(JSON.parse(data));
  };
  
  const restoreTableATM = () => {
  let data ='{"aggregation":{"model":{"amount_sek":"sum","missingreceipt":"sum","lost_receipt":"sum","exclude_from_sie_export":"sum"}},"rowGrouping":{"model":["project_name"]},"pinnedColumns":{},"columns":{"columnVisibilityModel":{"id":false,"accounting_category":false},"orderedFields":["__row_group_by_columns_group__","id","date","amount_sek","missingreceipt","type","project_name","merchant","country","merchant_category","name","note","admin_note","accounting_category","accounting_category_override","lost_receipt","exclude_from_sie_export"]},"preferencePanel":{"open":false},"filter":{"filterModel":{"items":[{"columnField":"accounting_category_override","id":53431,"operatorValue":"is","value":"1612 Kontantuttag"}],"linkOperator":"and","quickFilterValues":[],"quickFilterLogicOperator":"and"}},"sorting":{"sortModel":[{"field":"amount_sek","sort":"asc"}]},"pagination":{"pageSize":100,"page":0}}';
  apiRef.current.restoreState(JSON.parse(data));
  };
  
  const restoreTableInsurance = () => {
  let data ='{"aggregation":{"model":{"amount_sek":"sum","missingreceipt":"sum","lost_receipt":"sum","exclude_from_sie_export":"sum"}},"rowGrouping":{"model":["project_name"]},"pinnedColumns":{},"columns":{"columnVisibilityModel":{"id":false,"accounting_category":false},"orderedFields":["__row_group_by_columns_group__","id","date","amount_sek","missingreceipt","type","project_name","merchant","country","merchant_category","name","note","admin_note","accounting_category","accounting_category_override","lost_receipt","exclude_from_sie_export"]},"preferencePanel":{"open":false},"filter":{"filterModel":{"items":[{"columnField":"note","id":40158,"operatorValue":"contains","value":"örsäk"},{"columnField":"admin_note","operatorValue":"contains","id":70165,"value":"örsäk"}],"linkOperator":"or","quickFilterValues":[],"quickFilterLogicOperator":"and"}},"sorting":{"sortModel":[{"field":"amount_sek","sort":"desc"}]},"pagination":{"pageSize":100,"page":0}}';
  apiRef.current.restoreState(JSON.parse(data));
  };
  
  const restoreTableMissing = () => {
  let data ='{"aggregation":{"model":{"amount_sek":"sum","missingreceipt":"sum","lost_receipt":"sum","exclude_from_sie_export":"sum"}},"rowGrouping":{"model":["project_name"]},"pinnedColumns":{},"columns":{"columnVisibilityModel":{"id":false,"accounting_category":false},"orderedFields":["__row_group_by_columns_group__","id","date","amount_sek","missingreceipt","type","project_name","merchant","country","merchant_category","name","note","admin_note","accounting_category","accounting_category_override","lost_receipt","exclude_from_sie_export"]},"preferencePanel":{"open":false},"filter":{"filterModel":{"items":[{"columnField":"lost_receipt","id":17436,"operatorValue":"is","value":1}],"linkOperator":"and","quickFilterValues":[],"quickFilterLogicOperator":"and"}},"sorting":{"sortModel":[{"field":"amount_sek","sort":"asc"}]},"pagination":{"pageSize":100,"page":0}}';
  apiRef.current.restoreState(JSON.parse(data));
  };
  
  const restoreTableNocat = () => {
  let data ='{"aggregation":{"model":{"amount_sek":"sum","missingreceipt":"sum","lost_receipt":"sum","exclude_from_sie_export":"sum"}},"rowGrouping":{"model":[]},"pinnedColumns":{},"columns":{"columnVisibilityModel":{"id":false,"accounting_category":false},"orderedFields":["id","date","amount_sek","missingreceipt","type","project_name","merchant","country","merchant_category","name","note","admin_note","accounting_category","accounting_category_override","lost_receipt","exclude_from_sie_export"]},"preferencePanel":{"open":false},"filter":{"filterModel":{"items":[{"columnField":"accounting_category_override","id":29601,"operatorValue":"is","value":"6590 Okategoriserat"}],"linkOperator":"and","quickFilterValues":[],"quickFilterLogicOperator":"and"}},"sorting":{"sortModel":[{"field":"date","sort":"desc"}]},"pagination":{"pageSize":100,"page":0}}';
  apiRef.current.restoreState(JSON.parse(data));
  };
  
  const columns: GridColDef[] = [
    { field: 'id', headerName: 'id', type:'number', },
    { field: 'date', headerName: 'Datum', type:'dateTime', valueGetter: getDate},
    { field: 'amount_sek', headerName: 'Summa (SEK)', type:'number' },
    { field: 'missingreceipt', headerName: 'Saknas kvitto?', type:'number', renderCell: getIcon, align:'center'
    },

    { field: 'type', headerName: 'Typ', type:'string', },

    { field: 'project_name', headerName: 'Projekt', type:'string'},
    { field: 'merchant', headerName: 'Försäljningsställe', type:'string' },
    { field: 'country', headerName: 'Land', type:'string', renderCell: getFlag},
    { field: 'merchant_category', headerName: 'Kategori (auto)', type:'string'},
    { field: 'name', headerName: 'Namn', type:'string' },
    { field: 'note', headerName: 'Notering', type:'string' },
    { field: 'admin_note', headerName: 'Admin Notering', type:'string', editable: true },
    { field: 'accounting_category', headerName: 'Kategori (auto bokföring)', type:'singleSelect', valueOptions: [
      '1612 Kontantuttag',
      '1699 Vidarefaktureras',
      '4210 Material',
      '4230 Resor',
      '4240 Kost',
      '4260 Aktiviteter',
      '5800 Resor, personal och arbetsgrupper',
      '5840 Mat vid resor,  personal och arbetsgrupper',
      '5860 Logi, personal och arbetsgrupper',
      '5990 Övrigt reklam och PR',
      '6210 Telefon',
      '6590 Övriga kostnader',
      '7631 Personalrepresentation',
      '7640 Personalkaffe'
    ],editable:false},
    { field: 'accounting_category_override', headerName: 'Kategori (bokföring)', type:'singleSelect', valueOptions: [
      '1612 Kontantuttag',
      '1699 Vidarefaktureras',
      '4210 Material',
      '4230 Resor',
      '4240 Kost',
      '4260 Aktiviteter',
      '5800 Resor, personal och arbetsgrupper',
      '5840 Mat vid resor,  personal och arbetsgrupper',
      '5860 Logi, personal och arbetsgrupper',
      '5990 Övrigt reklam och PR',
      '6210 Telefon',
      '6590 Övriga kostnader',
      '7631 Personalrepresentation',
      '7640 Personalkaffe'
    ],editable:true,
    valueGetter: getCategory,
  groupingValueGetter: getCategory},
  
  
  { field: 'project_id', headerName: 'Projekt-ID (Auto)', type:'text',editable:false },
  { field: 'cost_center_id', headerName: 'Resultatenhet (RE) (Auto)', type:'text',editable:false },
  { field: 'cost_object_id', headerName: 'Kostnadsbärare (KBR) (Auto)', type:'text',editable:false },
  { field: 'project_id_override', headerName: 'Projekt-ID', type:'text',editable:true, valueGetter:getProjectId },
  { field: 'cost_center_id_override', headerName: 'Resultatenhet (RE)', type:'text',editable:true, valueGetter:getCostCenter },
  { field: 'cost_object_id_override', headerName: 'Kostnadsbärare (KBR)', type:'text',editable:true, valueGetter:getCostObject },
  
  { field: 'lost_receipt', headerName: 'Kvitto borttappat', type:'singleSelect', valueOptions: [
    { value: 1, label: 'Ja' },
    { value: 0, label: 'Nej' },
  ],editable:true, renderCell: getCheckIcon, align:'center'},
  
  { field: 'exclude_from_sie_export', headerName: 'Exkludera från SIE', type:'singleSelect', valueOptions: [
    { value: 1, label: 'Ja' },
    { value: 0, label: 'Nej' },
  ],editable:true, renderCell: getCheckIcon, align:'center'},

  ];
  
  function CustomToolbar() {
  return (
    <GridToolbarContainer >
    
      <GridToolbarFilterButton />
    <GridToolbarExport />
  {/* <Button color="primary" onClick={getTableState}>Get State</Button> */}
    <Button color="primary" onClick={restoreTableToday}>Idag</Button>
    <Button color="primary" onClick={restoreTableYesterday}>Igår</Button>
    <Button color="primary" onClick={restoreTableATM}>ATM</Button>
    <Button color="primary" onClick={restoreTableInsurance}>Försäkring</Button>
    <Button color="primary" onClick={restoreTableMissing}>Kvitto borta</Button>
    <Button color="primary" onClick={restoreTableNocat}>Okategoriserade</Button>
    <GridToolbarQuickFilter sx={{ marginLeft: "auto" }} />
    </GridToolbarContainer>
  );
  }
  
  return (
    <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '85vh',
        margin:40 }}>
        
    <DataGridPremium 
      apiRef={apiRef} 
      loading={loading}
      initialState={{
        columns: {
          columnVisibilityModel: {
            id: false,
            accounting_category: false,
            project_id: false,
            cost_center_id: false,
            cost_object_id: false
          },
        },
        aggregation: {
          model: {
            amount_sek: 'sum',
            missingreceipt: 'sum',
            lost_receipt: 'sum',
            exclude_from_sie_export: 'sum',
          },
        }
      }} 
      localeText={svSE.components.MuiDataGrid.defaultProps.localeText}
      sx={{ fontSize: 9 }} 
      rows={tableData} 
      columns={columns}  
      density="compact" 
      components={{ Toolbar: CustomToolbar, LoadingOverlay: LinearProgress}} 
      disableColumnSelector
      disableDensitySelector
      componentsProps={{
        toolbar: {
          showQuickFilter: true,
          quickFilterProps: { debounceMs: 500 },
        },
      }} 
      processRowUpdate={savetoDB}
      experimentalFeatures={{ 
        aggregation: true,
        newEditingApi: true }} 
       />
   </div>
  );
}



export default Wisetable;
