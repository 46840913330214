import './App.css';
import * as React from "react";
import { useState,useEffect } from "react";
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { shadows } from '@mui/system';
import { saveAs } from "file-saver";
import {encode, decode, labels} from 'windows-1252';


function Users() {

const idag = new Date().toJSON().slice(0, 10);;

const defaultValues = {startdate:"2024-01-01",enddate:idag};
const [formValues, setFormValues] = useState(defaultValues);
const [sieData, setSieData] = useState([]);

const handleChange = (e) => {
  const { name, value } = e.target;
  setFormValues({
	...formValues,
	[name]: value,
  });
};

const createSieFile = (rawdata) => {
	
	var legacy = require('legacy-encoding');
	var buffer = legacy.encode(rawdata, "cp1252");
	
	  var blob = new Blob([buffer], { type: "text/plain;charset=Windows-1252" });
	  saveAs(blob, "scoutwise.si");
  };
	  
 async function handleSubmit(event){
		  event.preventDefault();
		  await fetch("https://scoutwise.haag.se/api/sie", { 
			  method: "post",
			  body: JSON.stringify(formValues)
			})
			.then(response =>  response.text())
			.then(response => { createSieFile(response); })
		 ;
		 
		};
	

	  return (
		<div style={{
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			height: '85vh',
			margin:40 }}>
			<Box sx={{
				 display: 'flex',
				 alignItems: 'center',
				 boxShadow: 2,
				 justifyContent: 'center',
				 p:10,
				 width:600,
			   }}
				 >   
			
			<form onSubmit={handleSubmit}>
			<h2>Ladda ned SIE-fil</h2>
			<br /><br /><br />
			Startdatum
			<TextField
			  margin="dense"
			  id="startdate"
			  type="date"
			  fullWidth
			  variant="standard"
			  name="startdate"
			  value={formValues.startdate}
			  onChange={handleChange}
			/>
			<br /><br /><br />
			Slutdatum
			<TextField
			  margin="dense"
			  id="enddate"
			  type="date"
			  fullWidth
			  variant="standard"
			  name="enddate"
			  value={formValues.enddate}
			  onChange={handleChange}
			/>
	<br /><br /><br /><br /><br /><br /><br />
			<Button type="submit" variant="contained">Ladda ned</Button>
			</form>
			  
			</Box>
			
			
		
		  
	   </div>
	  );
}
	
	
	
export default Users;