import './App.css';
import * as React from "react";
import { useState,useEffect } from "react";
import { DataGridPremium, GridColDef, svSE, GridToolbarContainer, GridToolbarExport,GridToolbarQuickFilter,GridToolbarFilterButton } from "@mui/x-data-grid-premium";
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

function Users() {
	  
	  const [tableData,setTableData]=useState([]);
	
		function loadTable() {
		fetch("https://scoutwise.haag.se/api/users")
			.then((response) => response.json())
			.then((data) => setTableData(data))
		}
	
	  useEffect(()=>{

		loadTable();
			
		}, []);
	  
	  const savetoDB = (newRow,oldRow) =>{
		  
		  let bodydata = {
			"table":"users",
			"row":newRow
		  }
		
		  fetch("https://scoutwise.haag.se/api/update", { 
			method: "post",
			body: JSON.stringify(bodydata)
		  })
		  return {...newRow};
		};

const defaultValues = {};

const [formValues, setFormValues] = useState(defaultValues);


const handleChange = (e) => {
  const { name, value } = e.target;
  setFormValues({
	...formValues,
	[name]: value,
  });
};

const [open, setOpen] = React.useState(false);

const handleClickOpen = () => {
		setOpen(true);
	  };
	
 const handleClose = () => {
		setOpen(false);
	  };
	  
const handleSubmit = (event) => {
		  event.preventDefault();
		  let bodydata = {
			  "table":"users",
			  "row":formValues
			}
			fetch("https://scoutwise.haag.se/api/add", { 
			  method: "post",
			  body: JSON.stringify(bodydata)
			})
			.then((data) => loadTable())
			.then((data) => setOpen(false));
		 ;
		 
		};
	
	  
	  const columns: GridColDef[] = [
		{ field: 'id', headerName: 'id', type:'integer', },
		{ field: 'fullname', headerName: 'Namn', type:'string', editable: true},
		{ field: 'email', headerName: 'Epost', type:'string', editable: true},
		{ field: 'username', headerName: 'Användarnamn', type:'string', editable: true},
		{ field: 'phash', headerName: 'Lösenord SHA1', type:'string', editable: true},
	  ];
	  
	  function CustomToolbar() {
		return (
		  <GridToolbarContainer >
		  
		  	<GridToolbarFilterButton />
			<GridToolbarExport />
			<Button color="primary" startIcon={<AddIcon />} onClick={handleClickOpen}>
				Lägg till
			  </Button>
			<GridToolbarQuickFilter sx={{ marginLeft: "auto" }} />
		  </GridToolbarContainer>
		);
	  }
	  
	  return (
		<div style={{
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			height: '85vh',
			margin:40 }}>
			
			<Dialog open={open} onClose={handleClose} >
			<form onSubmit={handleSubmit}>
				<DialogTitle>Lägg till Administratör</DialogTitle>
				<DialogContent sx={{minWidth: 500 }}>
				  <TextField
					autoFocus
					margin="dense"
					id="fullname"
					label="Namn"
					type="text"
					fullWidth
					variant="standard"
					name="fullname"
					value={formValues.fullname}
					onChange={handleChange}
				  />
				  <br /><br />
					<TextField
						margin="dense"
						id="email"
						label="Epost"
						type="text"
						fullWidth
						variant="standard"
						name="email"
						value={formValues.email}
						onChange={handleChange}
					  />
				  <br /><br />
				  <TextField
					  margin="dense"
					  id="username"
					  label="Användarnamn"
					  type="text"
					  fullWidth
					  variant="standard"
					  name="username"
					  value={formValues.username}
					  onChange={handleChange}
					/>
					
					<br /><br />
					  <TextField
						  margin="dense"
						  id="phash"
						  label="Lösenord SHA1"
						  type="text"
						  fullWidth
						  variant="standard"
						  name="phash"
						  value={formValues.phash}
						  onChange={handleChange}
						/>
						
				
				  
				</DialogContent>
				<DialogActions>
				  <Button onClick={handleClose}>Avbryt</Button>
				  <Button type="submit">Lägg till</Button>
				</DialogActions>
				</form>
			  </Dialog>
			  
			
			
			
			
		<DataGridPremium 
		  initialState={{
			columns: {
			  columnVisibilityModel: {
				id: false,
			  },
			},
		  }} 
		  localeText={svSE.components.MuiDataGrid.defaultProps.localeText}
		  sx={{ fontSize: 9 }} 
		  rows={tableData} 
		  columns={columns}  
		  density="compact" 
		  components={{ Toolbar: CustomToolbar}} 
		  disableColumnSelector
		  disableDensitySelector
		  componentsProps={{
			toolbar: {
			  showQuickFilter: true,
			  quickFilterProps: { debounceMs: 500 },
			},
		  }} 
		  processRowUpdate={savetoDB}
		  experimentalFeatures={{ 
			newEditingApi: true }} 
		   />
		  
	   </div>
	  );
}
	
	
	
export default Users;